<template>
  <el-card>
    <template #header>
      <span>Change Password</span>
    </template>
    <el-form
      :model="user"
      size="large"
      ref="user"
      label-position="top"
      inline
      status-icon
    >
      <el-row class="p-3">
        <el-col :span="24">
          <el-form-item
            label="Current Password"
            prop="current_password"
            :rules="[
              {
                required: true,
                message: 'Current password is required',
                trigger: ['blur', 'change'],
              },
            ]"
          >
            <el-input
              type="password"
              v-model="user.current_password"
              placeholder="Enter current password"
            />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item
            label="New Password"
            prop="new_password"
            :rules="[
              {
                required: true,
                message: 'New password is required',
                trigger: ['blur', 'change'],
              },
            ]"
          >
            <el-input
              type="password"
              v-model="user.new_password"
              placeholder="Enter new password"
            />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item
            label="Confirm Password"
            prop="confirm_password"
            :rules="[
              {
                required: true,
                message: 'Confirm password is required',
                trigger: ['blur', 'change'],
              },
            ]"
          >
            <el-input
              type="password"
              v-model="user.confirm_password"
              placeholder="Confirm password"
            />
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <el-form-item label="">
            <el-button
              type="primary"
              plain
              @click="changePassword('user')"
              :loading="loading"
              >Change Password</el-button
            ></el-form-item
          >
        </el-col>
      </el-row>
    </el-form>
  </el-card>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    user() {
      return this.$store.state.auth.user;
    },
  },
  methods: {
    changePassword(fields) {
      this.$refs[fields].validate((valid) => {
        if (valid) {
          this.loading = true;
          this.$endpoint.changePassword(this.user).then(
            (res) => {
              this.loading = false;
              this.user = {};
              this.$notify({
                title: "Success",
                message: res.data.message,
                type: "success",
              });
            },
            (error) => {
              this.loading = false;
              this.message =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();
              this.$notify({
                title: "Success",
                message: this.message,
                type: "error",
              });
            }
          );
        } else {
          return false;
        }
      });
    },
  },
};
</script>