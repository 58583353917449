import moment from "moment";

const API_ENDPOINT = "https://careersbackend.techsavanna.technology/api/v1/";
//const API_ENDPOINT = "http://127.0.0.1:8000/api/v1/";
// const BASE_URL = "https://careers.techsavanna.technology/";
const BASE_URL = "https://careersfrontend_test.techsavanna.technology/";

function dateFormat(date) {
  var timestamp = Date.parse(date);
  if (isNaN(timestamp) == false) {
    var d = new Date(timestamp);
    return moment(d).format("DD/MM/YYYY hh:mmA");
  }
}

function filterDate(date) {
  var timestamp = Date.parse(date);
  if (isNaN(timestamp) == false) {
    var d = new Date(timestamp);
    return moment(d).format("YYYY-MM-DD");
  }
}

function currencyFormat(amount, decimal = 2) {
  return Number(amount).toLocaleString(undefined, {
    minimumFractionDigits: decimal,
  });
}

function formatAmount(row, col, value, index) {
  return Number(value ? value : 0).toLocaleString(undefined, {
    minimumFractionDigits: 2,
  });
}

function formatDate(row, col, value, index) {
  var timestamp = Date.parse(value);
  if (isNaN(timestamp) == false) {
    var d = new Date(timestamp);
    return moment(d).format("DD/MM/YYYY");
  }
}

function formatTimestamp(row, col, value, index) {
  var timestamp = Date.parse(value);
  if (isNaN(timestamp) == false) {
    var d = new Date(timestamp);
    return moment(d).format("DD/MM/YYYY hh:mmA");
  }
}

function formatMonth(value) {
  return moment(value).format("MMM YYYY");
}

function formatBoolean(row, col, value, index) {
  if (value) {
    return "Yes";
  } else {
    return "No";
  }
}

function formatStatus(row, col, value, index) {
  if (value) {
    return "Active";
  } else {
    return "Inactive";
  }
}

function formatTransactionStatus(row, col, value, index) {
  if (value == 1) {
    return "Completed";
  } else if (value == 2) {
    return "Failed & Refunded";
  } else {
    return "Cancelled";
  }
}

function formatPaymentsStatus(value) {
  if (value == 1) {
    return "Completed";
  } else if (value == 2) {
    return "Failed & Refunded";
  } else {
    return "Cancelled";
  }
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

function formatCardNumber(s) {
  return s.toString().replace(/\d{4}(?=.)/g, "$& ");
}

function cardNumber(s) {
  return s.toString().substring(0, 4) + " **** **** " + s.toString().slice(-4);
}

function getInitials(str) {
  return str
    .split(/\s/)
    .reduce((response, word) => (response += word.slice(0, 1)), "");
}

const intervals = [
  { label: "year", seconds: 31536000 },
  { label: "month", seconds: 2592000 },
  { label: "day", seconds: 86400 },
  { label: "hour", seconds: 3600 },
  { label: "minute", seconds: 60 },
  { label: "second", seconds: 1 },
];

function timeSince(date) {
  if (typeof date !== "object") {
    date = new Date(date);
  }
  const seconds = Math.floor((Date.now() - date) / 1000);
  const interval = intervals.find((i) => i.seconds < seconds);
  const count = Math.floor(seconds / interval.seconds);
  return `${count} ${interval.label}${count !== 1 ? "s" : ""} ago`;
}

function getRandomString(length) {
  var randomChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  var result = "";
  for (var i = 0; i < length; i++) {
    result += randomChars.charAt(
      Math.floor(Math.random() * randomChars.length)
    );
  }
  return result;
}

export default {
  API_ENDPOINT,
  BASE_URL,
  dateFormat,
  filterDate,
  formatDate,
  formatMonth,
  formatBoolean,
  formatStatus,
  formatTransactionStatus,
  formatPaymentsStatus,
  getBase64,
  currencyFormat,
  formatAmount,
  capitalizeFirstLetter,
  formatCardNumber,
  cardNumber,
  timeSince,
  getRandomString,
  getInitials,
  formatTimestamp,
};
