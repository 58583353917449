import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import helpers from "./helpers";
import endpoint from "./services/api.module";
import moment from "moment";
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import CKEditor from '@ckeditor/ckeditor5-vue';

const app = createApp(App).use(store).use(moment).use(router).use(ElementPlus).use(CKEditor);
app.config.globalProperties.$helpers = helpers;
app.config.globalProperties.$endpoint = endpoint;
app.config.globalProperties.$moment = moment;
app.mount("#app");
