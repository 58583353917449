import axios from "axios";
import authHeader from "./auth.header";
import helpers from "../helpers";
import router from "../router";
import store from "../store";

const API_URL = helpers.API_ENDPOINT;

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      //place your reentry code
      // toaster.error("Session has expired. Please login in again");
      store.dispatch("auth/logout");
      router.push("/login");
      return false;
    }
    return Promise.reject(error);
  }
);

class ApiService {
  getPersonnel(data) {
    return axios.post(API_URL + "client/employees", data, {
      headers: authHeader(),
    });
  }
  getUsers(data) {
    return axios.post(API_URL + "client/users", data, {
      headers: authHeader(),
    });
  }
  changePassword(data) {
    return axios.post(API_URL + "change-password", data, {
      headers: authHeader(),
    });
  }
  getProfilePhoto(id) {
    return axios.get(API_URL + "profile-photo/" + id, {
      headers: authHeader(),
    });
  }
  getDashboardStats(data) {
    return axios.post(API_URL + "client/dashboard", data, {
      headers: authHeader(),
    });
  }
  getDepartments(data) {
    return axios.post(API_URL + "client/departments", data, {
      headers: authHeader(),
    });
  }
  getRole(data) {
    return axios.post(API_URL + "client/user-roles", data, {
      headers: authHeader(),
    });
  }
  getApplications(data) {
    return axios.post(API_URL + "applications/filter", data, {
      headers: authHeader(),
    });
  }
  myApplications() {
    return axios.get(API_URL + "my-applications", {
      headers: authHeader(),
    });
  }
  getDashboard(data) {
    return axios.post(API_URL + "dashboard", data, {
      headers: authHeader(),
    });
  }
  signup(data) {
    return axios.post(API_URL + "signup", data, {
      headers: authHeader(),
    });
  }
  signin(data) {
    return axios.post(API_URL + "signin", data, {
      headers: authHeader(),
    });
  }
  saveOrganization(data) {
    if (data.id) {
      return axios.put(API_URL + "organization/" + data.id, data, {
        headers: authHeader(),
      });
    } else {
      return axios.post(API_URL + "organization", data, {
        headers: authHeader(),
      });
    }
  }
  savePersonnel(data) {
    if (data.id) {
      return axios.put(API_URL + "personnel/" + data.id, data, {
        headers: authHeader(),
      });
    } else {
      return axios.post(API_URL + "personnel", data, { headers: authHeader() });
    }
  }
  getCareerProfile(id) {
    return axios.get(API_URL + "personnel/" + id, { headers: authHeader() });
  }
  saveEducation(data) {
    if (data.id) {
      return axios.put(API_URL + "candidate/education/" + data.id, data, {
        headers: authHeader(),
      });
    } else {
      return axios.post(API_URL + "candidate/education", data, { headers: authHeader() });
    }
  }
  saveExperience(data) {
    if (data.id) {
      return axios.put(API_URL + "candidate/experience/" + data.id, data, {
        headers: authHeader(),
      });
    } else {
      return axios.post(API_URL + "candidate/experience", data, { headers: authHeader() });
    }
  }
  saveDocument(data) {
    if (data.id) {
      return axios.put(API_URL + "candidate/documents/" + data.id, data, {
        headers: authHeader(),
      });
    } else {
      return axios.post(API_URL + "candidate/documents", data, { headers: authHeader() });
    }
  }
  saveApplication(data) {
    if (data.id) {
      return axios.put(API_URL + "applications/" + data.id, data, {
        headers: authHeader(),
      });
    } else {
      return axios.post(API_URL + "applications", data, { headers: authHeader() });
    }
  }
  batchApplication(data) {
    return axios.post(API_URL + "applications/batch", data, { headers: authHeader() });
  }
  batchEmployees(company_id, data) {
    return axios.post(API_URL + "batch-employees/" + company_id, data, {
      headers: authHeader(),
    });
  }
  batchExpenseCategories(company_id, data) {
    return axios.post(API_URL + "expense-categories/" + company_id, data, {
      headers: authHeader(),
    });
  }
  saveRole(data) {
    if (data.id) {
      return axios.put(API_URL + "roles/" + data.id, data, {
        headers: authHeader(),
      });
    } else {
      return axios.post(API_URL + "roles", data, { headers: authHeader() });
    }
  }
  saveDepartment(data) {
    if (data.id) {
      return axios.put(API_URL + "departments/" + data.id, data, {
        headers: authHeader(),
      });
    } else {
      return axios.post(API_URL + "departments", data, {
        headers: authHeader(),
      });
    }
  }
  applyNow(data) {
    if (data.id) {
      return axios.put(API_URL + "appilcations/" + data.id, data, {
        headers: authHeader(),
      });
    } else {
      return axios.post(API_URL + "applications", data, {
        headers: authHeader(),
      });
    }
  }
  saveUser(data) {
    if (data.id) {
      return axios.put(API_URL + "users/" + data.id, data, {
        headers: authHeader(),
      });
    } else {
      return axios.post(API_URL + "users", data, { headers: authHeader() });
    }
  }
  forgotPassword(data) {
    
    return axios.post(API_URL + "forgot-password", data, {
      headers: authHeader(),
    });
  }
  resetPassword(data) {
     const headers = {
       "Content-Type": "application/json",
        // Replace with your front-end domain
     };
    return axios.post(API_URL + "reset-password", data, { headers });
  }

  deletePersonnel(id) {
    return axios.delete(API_URL + "personnel/" + id, { headers: authHeader() });
  }
  deleteDocument(id) {
    return axios.delete(API_URL + "candidate/documents/" + id, { headers: authHeader() });
  }
  deleteEducation(id) {
    return axios.delete(API_URL + "candidate/education/" + id, { headers: authHeader() });
  }
  deleteExperience(id) {
    return axios.delete(API_URL + "candidate/experience/" + id, { headers: authHeader() });
  }
  deleteUser(id) {
    return axios.delete(API_URL + "users/" + id, { headers: authHeader() });
  }
  sendLoginCredentials(id) {
    return axios.get(API_URL + "login-credentials/" + id, {
      headers: authHeader(),
    });
  }
  deleteRole(id) {
    return axios.delete(API_URL + "roles/" + id, { headers: authHeader() });
  }
  deleteDepartment(id) {
    return axios.delete(API_URL + "departments/" + id, {
      headers: authHeader(),
    });
  }

  downloadCv(data) {
    return axios
      .post(API_URL + "download/cv", data, {
        headers: authHeader(),
        responseType: "arraybuffer",
      })
      .then((response) => {
        let blob = new Blob([response.data], {
          type: "application/pdf",
        });
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = data.first_name+" "+data.last_name+" CV.pdf";
        link.click();
        return true;
      });
  }
  downloadCoverLetter(data) {
    return axios
      .post(API_URL + "download/cover-letter", data, {
        headers: authHeader(),
        responseType: "arraybuffer",
      })
      .then((response) => {
        let blob = new Blob([response.data], {
          type: "application/pdf",
        });
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = data.applicant+" Cover Letter.pdf";
        link.click();
        return true;
      });
  }
}

export default new ApiService();
