import axios from "axios";
import helpers from "../helpers";
import authHeader from "./auth.header";

const API_URL = helpers.API_ENDPOINT;
let payload = {};
let user = JSON.parse(localStorage.getItem("user"));
if (user && user.company_id) {
  payload = { company_id: user.company_id };
}
class CompanyService {
  getCompanyBranches() {
    return axios
      .post(API_URL + "client/company-branches", payload, {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data.data;
      });
  }
  getEmployeeProfile(id) {
    return axios
      .get(API_URL + "personnel/" + id, {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data.data;
      });
  }
  getEmploymentTypes() {
    return axios
      .post(API_URL + "client/employment-types", payload, {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data.data;
      });
  }
  saveEmploymentType(data) {
    data.company_id = payload.company_id;
    if (data.id) {
      return axios
        .put(API_URL + "employment-types/" + data.id, data, {
          headers: authHeader(),
        })
        .then((response) => {
          return response;
        });
    } else {
      return axios
        .post(API_URL + "employment-types", data, {
          headers: authHeader(),
        })
        .then((response) => {
          return response;
        });
    }
  }
  deleteEmploymentType(id) {
    return axios.delete(API_URL + "employment-types/" + id, {
      headers: authHeader(),
    });
  }

  getJobTitles() {
    return axios
      .get(API_URL + "job-titles", {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data.data;
      });
  }

  getJobs(data) {
    var page = data ? data.page : 1;
    return axios
      .post(API_URL + "client/job-titles?page=" + page, data, {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      });
  }
  getUnappliedJobs(data) {
    var page = data ? data.page : 1;
    return axios
      .post(API_URL + "unapplied-jobs?page=" + page, data, {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      });
  }
  getAllJobs(data) {
    var page = data ? data.page : 1;
    return axios
      .post(API_URL + "client/jobs?page=" + page, data, {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      });
  }
  saveJobTitle(data) {
    if (data.id) {
      return axios
        .put(API_URL + "job-titles/" + data.id, data, {
          headers: authHeader(),
        })
        .then((response) => {
          return response;
        });
    } else {
      return axios
        .post(API_URL + "job-titles", data, {
          headers: authHeader(),
        })
        .then((response) => {
          return response;
        });
    }
  }
  saveCompanyBranch(data) {
    data.parent_id = payload.company_id;
    if (data.id) {
      return axios
        .put(API_URL + "company/" + data.id, data, {
          headers: authHeader(),
        })
        .then((response) => {
          return response;
        });
    } else {
      return axios
        .post(API_URL + "company", data, {
          headers: authHeader(),
        })
        .then((response) => {
          return response;
        });
    }
  }
  deleteCompanyBranch(id) {
    return axios.delete(API_URL + "company/" + id, {
      headers: authHeader(),
    });
  }
  deleteJobTitle(id) {
    return axios.delete(API_URL + "job-titles/" + id, {
      headers: authHeader(),
    });
  }
  uploadLogo(id, data) {
    return axios.post(API_URL + "upload-logo/" + id, data, {
      headers: authHeader(),
    });
  }
}

export default new CompanyService();
