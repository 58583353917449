<template>
  <el-card>
    <template #header>
      <span>Access Logs</span>
      <span>
        <el-row>
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
            <el-input
              v-model="search"
              placeholder="Search"
              @keyup="searchRecord"
            />
          </el-col>
        </el-row>
      </span>
    </template>
    <el-table :data="access_logs" ref="elTable" v-loading="loading">
      <el-table-column prop="user" label="User" />
      <el-table-column prop="method" label="Action" />
      <el-table-column prop="request" label="Request" />
      <el-table-column prop="response" label="Response" />
      <el-table-column
        prop="created_at"
        label="Timestamp"
        :formatter="$helpers.formatTimestamp"
      />
    </el-table>
    <el-pagination
      background
      layout="total, sizes, prev, pager, next"
      :page-sizes="[10, 25, 50, 100]"
      :total="pagination.meta ? pagination.meta.total : 0"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
  </el-card>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      search: "",
      pagination: { page: 1, size: 10 },
    };
  },
  computed: {
    access_logs() {
      this.loading = true;
      if (this.$store.state.auth.access_logs != undefined) {
        this.loading = false;
        this.pagination.meta = this.$store.state.auth.access_logs.meta;
        return this.$store.state.auth.access_logs.data;
      }
    },
  },
  mounted() {
    this.$store.dispatch("auth/getAccessLogs", {
      page: this.pagination.page,
      size: this.pagination.size,
      search: this.search,
    });
  },
  methods: {
    handleCurrentChange(val) {
      this.pagination.page = val;
      this.$store.dispatch("auth/getAccessLogs", {
        page: this.pagination.page,
        size: this.pagination.size,
        search: this.search,
      });
    },
    handleSizeChange(val) {
      this.pagination.size = val;
      this.$store.dispatch("auth/getAccessLogs", {
        page: this.pagination.page,
        size: this.pagination.size,
        search: this.search,
      });
    },
    searchRecord() {
      this.$store.dispatch("auth/getAccessLogs", {
        page: this.pagination.page,
        size: this.pagination.size,
        search: this.search,
      });
    },
  },
};
</script>
