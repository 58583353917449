import AccountService from "../services/account.service";

export const account = {
  namespaced: true,
  state: {},
  actions: {
    balance({ commit }, data) {
      return AccountService.getBalance(data).then((data) => {
        commit("balance", data);
        return Promise.resolve(data);
      });
    },
    notifications({ commit }, data) {
      return AccountService.getNotifications(data).then((data) => {
        commit("notifications", data);
        return Promise.resolve(data);
      });
    },
    notify({ commit }, data) {
      return AccountService.Notify(data).then((data) => {
        commit("notify", data);
        return Promise.resolve(data);
      });
    },
    delete({ commit }, data) {
      return AccountService.Delete(data).then((data) => {
        commit("delete", data);
        return Promise.resolve(data);
      });
    },
  },
  mutations: {
    balance(state, data) {
      state.balance = data;
    },
    notifications(state, data) {
      state.notifications = data;
    },
    notify(state, data) {
      state.notify = data;
    },
    delete(state, data) {
      state.delete = data;
    },
  },
};
