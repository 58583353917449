import { createStore } from "vuex";
import { auth } from "./auth.module";
import { dictionary } from "./dictionary.module";
import { company } from "./company.module";
import { account } from "./account.module";

const store = createStore({
  modules: {
    auth,
    dictionary,
    company,
    account
  },
});

export default store;
