import CompanyService from "../services/company.service";

export const company = {
  namespaced: true,
  state: {},
  actions: {
    getCompanyBranches({ commit }, data) {
      return CompanyService.getCompanyBranches(data).then((data) => {
        commit("company_branches", data);
        return Promise.resolve(data);
      });
    },
    getEmployeeProfile({ commit }, id) {
      return CompanyService.getEmployeeProfile(id).then((data) => {
        commit("employee_profile", data);
        return Promise.resolve(data);
      });
    },
    saveCompanyBranch({ commit }, data) {
      return CompanyService.saveCompanyBranch(data).then((data) => {
        commit("save_company_branch", data);
        return Promise.resolve(data);
      });
    },
    deleteCompanyBranch({ commit }, data) {
      return CompanyService.deleteCompanyBranch(data).then((data) => {
        commit("delete_company_branch", data);
        return Promise.resolve(data);
      });
    },

    getJobTitles({ commit }, data) {
      return CompanyService.getJobTitles(data).then((data) => {
        commit("job_titles", data);
        return Promise.resolve(data);
      });
    },
    saveJobTitle({ commit }, data) {
      return CompanyService.saveJobTitle(data).then((data) => {
        commit("save_job_title", data);
        return Promise.resolve(data);
      });
    },
    deleteJobTitle({ commit }, data) {
      return CompanyService.deleteJobTitle(data).then((data) => {
        commit("delete_job_title", data);
        return Promise.resolve(data);
      });
    },

    getEmploymentTypes({ commit }, data) {
      return CompanyService.getEmploymentTypes(data).then((data) => {
        commit("employment_types", data);
        return Promise.resolve(data);
      });
    },
    saveEmploymentType({ commit }, data) {
      return CompanyService.saveEmploymentType(data).then((data) => {
        commit("save_employment_type", data);
        return Promise.resolve(data);
      });
    },
    deleteEmploymentType({ commit }, data) {
      return CompanyService.deleteEmploymentType(data).then((data) => {
        commit("delete_employment_type", data);
        return Promise.resolve(data);
      });
    },
  },
  mutations: {
    company_branches(state, data) {
      state.company_branches = data;
    },
    employee_profile(state, data) {
      state.employee_profile = data;
    },
    save_company_branch(state, data) {
      state.save_company_branch = data;
    },
    delete_company_branch(state, data) {
      state.delete_company_branch = data;
    },

    job_titles(state, data) {
      state.job_titles = data;
    },
    save_job_title(state, data) {
      state.save_job_title = data;
    },
    delete_job_title(state, data) {
      state.delete_job_title = data;
    },

    employment_types(state, data) {
      state.employment_types = data;
    },
    save_employment_type(state, data) {
      state.save_employment_type = data;
    },
    delete_employment_type(state, data) {
      state.delete_employment_type = data;
    },
  },
};
