import axios from "axios";
import helpers from "../helpers";
import authHeader from "./auth.header";

const API_URL = helpers.API_ENDPOINT;
let payload = {};
let user = JSON.parse(localStorage.getItem("user"));
if (user && user.company_id) {
  payload = { company_id: user.company_id, personnel_id: user.id };
}
class AccountService {
  getNotifications() {
    return axios
      .get(API_URL + "notifications", {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data.data;
      });
  }
  Notify(data) {
    return axios
      .put(API_URL + "notifications/" + data.id, data, {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      });
  }
  Delete() {
    return axios
      .post(API_URL + "notifications/delete", payload, {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      });
  }
}

export default new AccountService();
