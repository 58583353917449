import axios from "axios";
import helpers from "../helpers";
import authHeader from "./auth.header";

const API_URL = helpers.API_ENDPOINT;
let payload = {};
let user = JSON.parse(localStorage.getItem("user"));
if (user && user.company_id) {
  payload = { company_id: user.company_id };
}
class DictionaryService {
  getDepartments() {
    return axios
      .post(API_URL + "client/departments", payload, {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data.data;
      });
  }
  getEmployees() {
    return axios
      .post(API_URL + "client/employees", payload, {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data.data;
      });
  }
  getBanks() {
    return axios
      .post(API_URL + "gateway/interswitch/wallet-providers", payload, {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data.providers;
      });
  }
  getCountries() {
    return axios.get(API_URL + "countries").then((response) => {
      return response.data.data;
    });
  }
  getIndustries() {
    return axios.get(API_URL + "industries").then((response) => {
      return response.data.data;
    });
  }
}

export default new DictionaryService();
