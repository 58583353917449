<template>
  <div class="card">
    <div class="card-header">
      <h5 class="card-title">Account Settings</h5>
    </div>
    <div class="card-body">
      <el-form :model="user" size="large" ref="user" label-position="top" :inline="true">
        <el-row>
          <el-col :span="24" center>
            <label class="avatar avatar-xxl profile-cover-avatar m-0" for="edit_img">
              <img class="avatar-img" :src="user.profile_photo" alt="Profile Image" />
              <input type="file" ref="file" style="display: none" @change="uploadProfilePhoto" />
              <span class="avatar-edit" @click="$refs.file.click()">
                <i class="fa fa-camera"></i>
              </span>
            </label>
          </el-col>
          <el-col :span="24">
            <el-form-item label="Name" prop="name" :rules="[
              {
                required: true,
                message: 'Name is required',
                trigger: ['blur'],
              },
            ]">
              <el-input v-model="user.name" placeholder="Enter name" /> </el-form-item></el-col>
          <el-col :span="24">
            <el-form-item label="Phone Number" prop="phone" :rules="[
              {
                required: true,
                message: 'Phone number is required',
                trigger: ['blur'],
              },
            ]">
              <el-input v-model="user.phone" placeholder="Enter phone number" /> </el-form-item></el-col>
          <el-col :span="24">
            <el-form-item label="Email Address" prop="email" :rules="[
              {
                required: true,
                message: 'Email address is required',
                trigger: ['blur'],
              },
            ]">
              <el-input v-model="user.email" disabled placeholder="Enter email address" /> </el-form-item></el-col>
          <el-col :span="24">
            <el-form-item label="Role" prop="role_id" :rules="[
              {
                required: true,
                message: 'User role is required',
                trigger: ['blur'],
              },
            ]">
              <el-select disabled v-model="user.role_id" placeholder="Select user role">
                <el-option v-for="role in roles" :key="role.id" :label="role.name" :value="role.id" />
              </el-select> </el-form-item></el-col>
          <el-col :span="24" class="p-2 mt-2">
            <el-button type="primary" plain @click="submit(user)" :loading="loading">Save Changes</el-button></el-col>
        </el-row>
      </el-form>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      data: "",
      loading: false,
      roles: []
    };
  },
  computed: {
    user() {
      return this.$store.state.auth.user;
    },
  },
  created() {
    this.getRoles();
  },
  methods: {
    submit(data) {
      this.loading = true;
      data.slug = data.name;
      this.$endpoint.saveUser(data).then(
        (res) => {
          this.loading = false;
          this.$notify({
            title: "Success",
            message: res.data.message,
            type: "success",
          });
        },
        (error) => {
          this.loading = false;
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.$notify({
            title: "Error",
            message: this.message,
            type: "error",
          });
        }
      );
    },
    getRoles() {
      let data = {};
      data.company_id = this.$store.state.auth.user.company_id;
      return this.$endpoint
        .getRole(data)
        .then((response) => {
          this.roles = response.data.data.roles;
        })
        .catch(() => { });
    },
    async uploadProfilePhoto(event) {
      let file = event.target.files[0];
      await this.$helpers
        .getBase64(file)
        .then((data) => (this.user.profile_photo = data));
      this.submit(this.user);
    },
  },
};
</script>