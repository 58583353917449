import axios from "axios";
import helpers from "../helpers";
import authHeader from "./auth.header";

const API_URL = helpers.API_ENDPOINT;
let payload = {};
let user = JSON.parse(localStorage.getItem("user"));
if (user && user.company_id) {
  payload = { company_id: user.company_id, personnel_id: user.id };
}

class AuthService {
  login(user) {
    return axios
      .post(API_URL + "signin", user)
      .then((response) => {
        if (response.data.data.access_token) {
          localStorage.setItem("user", JSON.stringify(response.data.data));
        }

        return response.data.data;
      });
  }
  getAccessLogs(data) {
    var page = data ? data.page : 1;
    data.company_id = payload.company_id;
    return axios
      .post(API_URL + "access-logs?page=" + page, data, {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      });
  }
  logout() {
    localStorage.removeItem("user");
  }
}

export default new AuthService();
