import { createRouter, createWebHistory, createWebHashHistory } from "vue-router";
import Profile from "@/views/settings/Profile.vue";
import CareerProfile from "@/views/jobs/CareerProfile.vue";
import Password from "@/views/settings/Password.vue";
import Role from "@/views/settings/Role.vue";
import User from "@/views/settings/User.vue";
import AccessLogs from "@/views/settings/AccessLogs.vue";

const routes = [
  {
    path: "/auth",
    component: () => import("@/views/listing/AuthLayout.vue"),
    children: [
      {
        path: "/login",
        name: "Login",
        component: () => import("@/views/auth/SignIn.vue"),
      },
      {
        path: "/signup",
        name: "Signup",
        component: () => import("@/views/auth/SignUp.vue"),
      },
      {
        path: "/forgotPassword",
        name: "ForgotPassword",
        component: () => import("@/views/auth/ForgotPassword.vue"),
      },
      {
        path: "/reset-password/:token",
        name: "ResetPassword",
        component: () => import("@/views/auth/ResetPassword.vue"),
      },
      {
        path: "/",
        name: "Job Listing",
        component: () => import("@/views/listing/Jobs.vue"),
        meta: {
          requiresAuth: true,
          authorize: [],
        },
      },
      {
        path: "/job-details",
        name: "Job Details",
        component: () => import("@/views/listing/JobDetails.vue"),
      },
    ],
  },
  {
    path: "/home",
    component: () => import("@/views/layout/MainLayout.vue"),
    meta: {
      requiresAuth: true,
      authorize: [],
    },
    children: [
      {
        path: "/dashboard",
        name: "Home",
        component: () => import("@/views/auth/UserDashboard.vue"),
        meta: {
          title: "Home",
          authorize: [],
        },
      },
      {
        path: "/applications",
        name: "Applications",
        component: () => import("@/views/jobs/Applications.vue"),
        meta: {
          title: "Applications",
          authorize: [],
        },
      },
      {
        path: "/my-applications",
        name: "My Applications",
        component: () => import("@/views/jobs/MyApplications.vue"),
        meta: {
          title: "My Applications",
          authorize: [],
        },
      },
      {
        path: "/organization/branches",
        name: "Branches",
        component: () => import("@/views/organization/Branches.vue"),
        meta: {
          title: "Branches",
          authorize: [],
        },
      },
      {
        path: "/organization/departments",
        name: "Departments",
        component: () => import("@/views/organization/Departments.vue"),
        meta: {
          title: "Departments",
          authorize: [],
        },
      },
      {
        path: "/organization/job-titles",
        name: "Job Titles",
        component: () => import("@/views/organization/JobTitles.vue"),
        meta: {
          title: "Job Titles",
          authorize: [],
        },
      },
      {
        path: "/organization/employment-types",
        name: "Employment Types",
        component: () => import("@/views/organization/EmploymentTypes.vue"),
        meta: {
          title: "Employment Types",
          authorize: [],
        },
      },
      {
        path: "/employees",
        name: "Employees",
        component: () => import("@/views/employees/Index.vue"),
        meta: {
          title: "Employees",
          authorize: [],
        },
      },
      {
        path: "/employees/:id",
        name: "EmployeeProfile",
        component: () => import("@/views/employees/Profile.vue"),
        meta: {
          title: "Employee Profile",
          authorize: [],
        },
      },
      {
        name: "profile",
        path: "/settings/profile",
        component: Profile,
        meta: {
          title: "Profile Settings",
          authorize: [],
        },
      },
      {
        name: "Career Profile",
        path: "/career/profile",
        component: CareerProfile,
        meta: {
          title: "Career Profile",
          authorize: [],
        },
      },
      {
        name: "password",
        path: "/settings/password",
        component: Password,
        meta: {
          title: "Change Password",
          authorize: [],
        },
      },
      {
        name: "roles",
        path: "/settings/roles",
        component: Role,
        meta: {
          title: "Users",
          authorize: [],
        },
      },
      {
        name: "users",
        path: "/settings/users",
        component: User,
        meta: {
          title: "System Users",
          authorize: [],
        },
      },
      {
        name: "access-logs",
        path: "/settings/access-logs",
        component: AccessLogs,
        meta: {
          title: "Access Logs",
          authorize: [],
        },
      },
      {
        path: "/:catchAll(.*)",
        name: "Not Found",
        component: () => import("@/views/layout/NotFound.vue"),
        meta: {
          title: "Not Found",
          authorize: [],
        },
      },
    ],
  },
];

const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL),
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});
router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const { authorize } = to.meta;
  // const currentUser = localStorage.getItem('token');
  const isAuthenticated = localStorage.getItem("user");

  if (authorize) {
    if (!isAuthenticated) {
      // not logged in so redirect to login page with the return url
      return next("/login");
    }
  }
  next();
});
export default router;
