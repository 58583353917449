<template>
  <el-card>
    <template #header>
      <span>Roles & Permissions</span>
      <el-button type="success" plain @click="dialog = true"
        >Add New Role</el-button
      >
    </template>
    <el-dialog v-model="dialog" title="Role Permissions" width="35%">
      <el-form
        :model="role"
        size="large"
        ref="role"
        label-position="top"
        :inline="true"
      >
        <el-row>
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
            <el-form-item
              label="Role"
              prop="name"
              :rules="[
                {
                  required: true,
                  message: 'Role is required',
                  trigger: ['blur'],
                },
              ]"
            >
              <el-input
                v-model="role.name"
                placeholder="Enter role"
              /> </el-form-item
          ></el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
            <el-form-item label="Select Permissions">
              <el-tree
                :data="abilities"
                show-checkbox
                node-key="label"
                :default-checked-keys="ticked"
               ref="markupTree"
                :props="ticked"
                accordion
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialog = false">Cancel</el-button>
          <el-button
            type="success"
            plain
            @click="submit('role')"
            :loading="loading"
            >Save</el-button
          >
        </span>
      </template>
    </el-dialog>

    <el-row v-loading="loading" class="m-2">
      <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
        <el-tree
          :data="items"
          node-key="label"
          :default-expanded-keys="expanded_role"
          :props="selected_role"
          @node-click="getRolePermissions"
        >
          <template #default="{ node, data }">
            <span class="custom-tree-node">
              <span>{{ node.label }}</span>
              <span v-if="data.editable">
                <i class="fa fa-edit me-2 text-info" @click="editRole(data)">
                </i>
                <i
                  class="fa fa-trash me-4 text-danger"
                  @click="deleteRole(data.id)"
                >
                </i>
              </span>
            </span>
          </template>
        </el-tree>
      </el-col>
      <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
        <p>Permissions</p>
      <el-tag v-for="permission in ticked" :key="permission" class="m-1" type="success">{{permission}}</el-tag>
        <!-- <el-tree
          :data="abilities"
          show-checkbox
          node-key="label"
          :default-checked-keys="ticked"
          accordion
        /> -->
      </el-col>
    </el-row>
  </el-card>
</template>
<style>
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
<script>
export default {
  data: () => ({
    loading: false,
    dialog: false,
    selected_role: [],
    expanded_role: ["User Roles"],
    ticked: [],
    abilities: [],
    roles: [],
    groups: [],
    role: {},
    permission: {},
    group: {},
  }),

  computed: {
    items() {
      return [
        {
          label: "User Roles",
          children: this.roles,
        },
      ];
    },
  },

  created() {
    this.getRoles();
  },

  methods: {
    getRoles() {
          this.loading = true;
      let data = {};
      data.company_id = this.$store.state.auth.user.company_id;
      return this.$endpoint
        .getRole(data)
        .then((response) => {
          this.loading = false;
          this.roles = response.data.data.roles;
          this.groups = response.data.data.abilities;
          this.abilities = response.data.data.abilities;
        })
        .catch((error) => {
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.$notify({
            title: "Error",
            message: this.message,
            type: "error",
          });
        });
    },

    submit(validate) {
      this.$refs[validate].validate((valid) => {
        if (valid) {
          this.loading = true;
          this.role.ability_ids = Object.assign({}, this.$refs.markupTree.getCheckedKeys());
          console.log(this.role.ability_ids);
          this.role.title = this.role.name;
          this.role.company_id = this.$store.state.auth.user.company_id;
          console.log(this.$refs[validate]);
          this.$endpoint
            .saveRole(this.role)
            .then((response) => {
              this.getRoles();
              this.loading = false;
              this.dialog = false;

              this.$notify({
                title: "Success",
                message: response.data.message,
                type: "success",
              });
            })
            .catch((error) => {
              this.loading = false;
              this.message =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();
              this.$notify({
                title: "Error",
                message: this.message,
                type: "error",
              });
            });
        } else {
          return false;
        }
      });
    },

    editRole(item) {
      this.getRolePermissions(item);
      this.role = Object.assign({}, item);
      this.dialog = true;
    },

    deleteRole(id) {
      if (confirm("Do you really want to delete this role?")) {
        this.$endpoint.deleteRole(id).then(
          () => {
            this.getRoles();
          },
          (error) => {
            this.loading = false;
            this.message =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
            this.$toast.error(this.message);
          }
        );
      }
    },
    deleteGroup(item) {
      if (item.group_id) {
        if (confirm("Do you really want to delete this group?")) {
          this.$endpoint.deleteGroup(item.group_id).then(
            () => {
              this.getRoles();
            },
            (error) => {
              this.loading = false;
              this.message =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();
              this.$toast.error(this.message);
            }
          );
        }
      }
    },
    getRolePermissions(selected) {
      if (selected.name) {
        this.ticked = this.roles.find(
          (role) => role.name == selected.name
        ).ability_ids;
      }
    },
  },
};
</script>