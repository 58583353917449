import DictionaryService from "../services/dictionary.service";

export const dictionary = {
  namespaced: true,
  state: {},
  actions: {
    getDepartments({ commit }, data) {
      return DictionaryService.getDepartments(data).then((data) => {
        commit("departments", data);
        return Promise.resolve(data);
      });
    },
    getEmployees({ commit }, data) {
      return DictionaryService.getEmployees(data).then((data) => {
        commit("employees", data);
        return Promise.resolve(data);
      });
    },
    getBanks({ commit }) {
      return DictionaryService.getBanks().then((data) => {
        commit("banks", data);
        return Promise.resolve(data);
      });
    },
    getCountries({ commit }) {
      return DictionaryService.getCountries().then((data) => {
        commit("countries", data);
        return Promise.resolve(data);
      });
    },
    getIndustries({ commit }) {
      return DictionaryService.getIndustries().then((data) => {
        commit("industries", data);
        return Promise.resolve(data);
      });
    },
  },
  mutations: {
    departments(state, departments) {
      state.departments = departments;
    },
    employees(state, employees) {
      state.employees = employees;
    },
    banks(state, banks) {
      state.banks = banks;
    },
    countries(state, countries) {
      state.countries = countries;
    },
    industries(state, industries) {
      state.industries = industries;
    },
  },
};
